import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from './utils/ThemeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ThemeProvider>
        <App/>
    </ThemeProvider>
);

serviceWorkerRegistration.unregister();

reportWebVitals();
